<template>
  <footer id="main-footer" class="bg-dark text-center py-1">
    <div class="main-footer-container">
      <p>
        Copyright &copy; 2021 Apple Gsx, All rights Reserved v1.0.3
        <a href="http://beian.miit.gov.cn/"> 蜀ICP备20001697号-1</a>
      </p>
    </div>
  </footer>
</template>

<script setup>
</script>
<style scoped>
</style>
