import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {}
  },
  mutations: {
    changeUserInfo (state, e) {
      state.userInfo = e
      console.log(state.userInfo)
    }
  },
  actions: {
  },
  modules: {
  }
})
