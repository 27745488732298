module.exports = {
  getDate: getDate,
  date_timestamp: dateTimestamp,
  timestamp_date: timestampDate,
  getCurrentDate: getCurrentDate
}

function getDate () {
  var timestamp = Date.parse(new Date()) / 1000
  return timestamp
}

function dateTimestamp (date) {
  if (date != null && date !== 'null') {
    const d = new Date(date.replace(/-/g, '/'))
    const timestamp = Date.parse(d) / 1000 // 方法3
    return timestamp
  } else {
    return 0
  }
}

function timestampDate (timestamp) {
  var now = new Date(timestamp * 1000)
  var year = now.getFullYear() // 取得4位数的年份
  var month = now.getMonth() + 1 // 取得日期中的月份，其中0表示1月，11表示12月
  if (month < 10) {
    month = '0' + month
  }
  var date = now.getDate() // 返回日期月份中的天数（1到31）
  if (date < 10) {
    date = '0' + date
  }
  var hour = now.getHours() // 返回日期中的小时数（0到23）
  if (hour < 10) {
    hour = '0' + hour
  }
  var minute = now.getMinutes() // 返回日期中的分钟数（0到59）
  if (minute < 10) {
    minute = '0' + minute
  }
  var second = now.getSeconds() // 返回日期中的秒数（0到59）
  if (second < 10) {
    second = '0' + second
  }
  return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
}

function getCurrentDate () {
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  var nowDate = year + '-' + month + '-' + day

  const nowtimeStamp = Date.parse(nowDate) / 1000 - 28800
  return nowtimeStamp
}
