export const getTimeStamp = () => {
  var timestamp = Date.parse(new Date()) / 1000
  return timestamp
}

var utilsMd5 = require('./kay1686.js')
export const kay1686 = (array) => {
  var serviceToken = ''
  for (var i in array) {
    serviceToken = serviceToken + utilsMd5.hexMD5('' + array[i])
  }
  serviceToken = utilsMd5.hexMD5(serviceToken)
  return serviceToken
}
