function getTagInfo (str) {
  if (str === 'GSX-model') {
    // 型号
    return '该项信息已经过GSX认证，若颜色、容量，与本机不一致，则是换过壳、扩容机'
  } else if (str === 'GSX-activeTime') {
    // 激活日期
    return '此激活日期已经过GSX验证100%准确'
  } else if (str === 'register') {
    // 已注册设备
    return '“是” 代表设备已在GSX库中进行注册，“否”代表未注册或已注销'
  } else if (str === 'replacement') {
    // 官换机
    return '“Apple 售后换机后的产品（活跃设备），保修继承原始设备。'
  } else if (str === 'Retail') {
    // 零售机
    return '零售机，官方渠道购买的正品。'
  } else if (str === 'Brightstar') {
    // 资源机
    return '资源机，购买日期：1978-04-01，二手无保修，由苹果授权 Brightstar 销售。'
  } else if (str === 'Exception') {
    // 异常机
    return '异常的无保修产品。'
  } else if (str === 'Demo') {
    // 展示机
    return 'Apple Store 等用于展示的产品。'
  } else if (str === 'Personalized') {
    // 高端定制机
    return '高端/奢侈定制产品，如 24K 黄金 iPhone。'
  } else if (str === 'Identifier') {
    // 高端定制机
    return '这里指的是苹果的发布会，所发布的产品。例如iPhone12，1 指的是苹果的第12场发布会，所发布的第1款产品。'
  } else if (str === 'ProductType') {
    // 产品类型
    return '零售机：官方渠道购买的正品。<br>' +
      '<br>官换机：Apple 售后换机后的产品（活跃设备），保修继承原始设备。<br>' +
      '<br>BS机：又名：资源机，购买日期：1978-04-01，二手无保修，由苹果授权 Brightstar 销售。<br>' +
      '<br>更换机：又名：黑机（已更换产品的序列号），送修换机的原始设备。<br>' +
      '<br>展示机：Apple Store 等用于展示的产品。<br>' +
      '<br>官修机：Apple 售后维修过的设备，在 GSX 有维修案例。<br>' +
      '<br>官翻机：Apple 认证的翻新产品，提供标准的一年有限保修服务。<br>' +
      '<br>监管机：企业采购并统一配置管理的设备，通常有监管锁（MDM）。<br>' +
      '<br>借出机：送修时 Apple 提供给用户使用的借出设备，维修完成后需归还。<br>' +
      '<br>异常机：异常的无保修产品。<br>' +
      '<br>定制机：高端/奢侈定制产品，如 24K 黄金 iPhone。'
  } else if (str === 'Blacklist') {
    // 黑名单状态
    return '这里指是否为脏机，白 = 不是'
  } else if (str === 'NetWork') {
    // 黑名单状态
    return '全网通代表移动电信联通都可用，非全网通电信只能上网不能打电话，且只有4G。详细情况请联系运营商'
  } else if (str === 'SIMLock') {
    // 网络锁
    return '无锁代表该设备没有网络锁，不需要使用卡贴来激活设备。'
  } else if (str === 'Carrier') {
    // 运营商
    return 'Unlock代表无锁，可以随意进行刷机/升级'
  }
}

module.exports = {
  getTagInfo: getTagInfo
}
