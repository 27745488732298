<template>
  <nav id="navbar">
    <div class="navbar-left">
      <h1 class="logo">Apple Gsx 查询</h1>
      <ul>
        <!-- <template v-for="(item, index) in navbarList" :key="index">
          <li>
            <a
              href="javascript:void(0)"
              :data-href="item.ename"
              :data-index="index"
              @click="goAnchor"
              >{{ item.name }}</a
            >
          </li>
        </template>
        <li>
          <a
            href="https://www.zhihu.com/org/wan-ji-cha-xun"
            target="view_window"
            >验机攻略</a
          >
        </li> -->
        <li><a @click="handleClickSim">微信公众号</a></li>
      </ul>
    </div>
    <div class="navbar-right">
      <ul v-show="userInfo.data != ''">
        <li>
          <a @click="showServeModal = true" style="color: rgb(173, 173, 173)"
            >添加客服</a
          >
        </li>
        <!-- <li>
          <a
            >会员状态：
            <template v-if="userInfo.data.is_vip == true">永久会员</template>
            <template v-else>非会员</template>
          </a>
        </li>
        <li>
          <a>余额：{{ userInfo.data.money / 100 }} J币</a>
        </li>
        <li>
          <a @click="handleClickGetOrder(1, 'payOrder')">充值记录</a>
        </li> -->
        <!-- <li>
          <a @click="handleClickGetOrder(1)">查询历史</a>
        </li> -->
        <!-- <li @click="handleClickMoneyPay"><a>余额充值</a></li> -->
      </ul>
      <ul>
        <li @click="handleClickLogin" v-if="showLoginButton">
          <a style="cursor: pointer">登录</a>
        </li>
        <li v-else>
          <a style="color: red" @click="outLogin">退出登录</a>
        </li>
      </ul>
    </div>

    <el-dialog
      v-model="PayDialog"
      width="400px"
      title="充值中心"
      :before-close="PayDialogClose()"
    >
      <div class="moneyView">
        <div class="moneyView-title">
          <span style="font-size: 20px; font-weight: bold">请选择充值金额</span>
          <span style="font-size: 10px; margin-left: 10px"
            >点击下方数字进行充值</span
          >
          <span style="margin-left: 10px; font-size: 10px">1元 = 1个J币</span>
        </div>
        <div class="moneyView-money">
          <div
            class="money"
            v-for="(item, index) in money.data"
            :key="index"
            :class="item.borderStyle"
            @click="selectPayMoney(index)"
          >
            <div class="money-price">
              <span class="price">{{ item.amount / 100 }}元</span>
              <span class="price-i text-orange" v-if="item.handsel != 0"
                >送{{ item.handsel / 100 }}元</span
              >
            </div>
            <div class="vip">
              <span
                style="font-size: 12px; color: white"
                v-if="item.vip == true"
                >送永久会员</span
              >
            </div>
          </div>
        </div>
        <div class="payStyleView">
          <span style="font-size: 20px; font-weight: bold">请选择支付方式</span>
          <div class="payStyle">
            <div
              class="pay"
              v-for="(item, index) in moneyStyle"
              :key="index"
              @click="selectPayStyle(index)"
              :class="item.borderStyle"
            >
              <img :src="item.img" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <el-button type="primary" class="payStyleButton" @click="createOrder"
          >充值</el-button
        >
        <div style="font-size: 11px; margin-top: 8px; color: #b9411e">
          一经充值概不退款请知悉
        </div>
        <el-button
          type="danger"
          class="payStyleButton"
          @click="
            checkOrderModal = true;
            PayDialog = false;
          "
          >充值未到账点这里</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      v-model="checkOrderModal"
      title="支付单号验证"
      width="30%"
      :before-close="PayDialogClose()"
    >
      <el-input v-model="orderInput" placeholder="请输入你的支付单号" />
      <div style="margin: 10px 0; color: rgb(245, 108, 108)">
        <p style="display: inline">支付单号在哪里找到？</p>
        <a
          style="margin: 0 10px; text-decoration: underline"
          href="https://api.wanjichaxun.com/static/wechatOrder.jpg"
          target="_blank"
          >微信单号</a
        >
        <a
          style="margin: 0 10px; text-decoration: underline"
          href="https://api.wanjichaxun.com/static/alipayOrder.jpg"
          target="_blank"
          >支付宝单号</a
        >
      </div>
      <div class="payStyleView">
        <span style="font-size: 20px; font-weight: bold"
          >请选择使用的支付方式</span
        >
        <div class="payStyle">
          <div
            class="pay"
            v-for="(item, index) in moneyStyle"
            :key="index"
            @click="selectPayStyle(index)"
            :class="item.borderStyle"
          >
            <img :src="item.img" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 10px">
        <el-button type="primary" style="width: 30%" @click="checkInputOrder"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      v-model="qrDialog"
      width="400px"
      :title="payDialogTitle"
      top="30vh"
    >
      <div class="qrView">
        <template v-if="qrValue != ''">
          <div class="qrCode" style="border-right: 1px solid #d3d6dd">
            <qrcode-vue :value="qrValue" :size="150" level="H" />
          </div>
        </template>
        <div class="qrOrder">
          <p>充值用户：{{ userInfo.data.id }}</p>
          <p>支付服务：余额充值</p>
          <p>支付金额：{{ price }}</p>
          <p>赠送金额：{{ free }}</p>
        </div>
      </div>
      <div class="qrButton">
        <el-button type="primary" @click="checkOrder(null, true)"
          >已完成支付</el-button
        >
        <el-button type="primary" @click="closeQrDialog">重新支付</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-model="showSimModal"
      title="玩机查询"
      top="30vh"
      customClass="modalStyle"
      center
    >
      <!-- <div style="padding-bottom: 10px; text-align: center">
        <span style="font-size: 1.2rem; font-weight: bold"
          >请使用微信扫码领取<br />免费下单包邮到家</span
        >
      </div> -->
      <div class="scanModal">
        <img src="../style/img/qrcode_for_gh_c00a4ed6fe39_258.jpg" alt="" />
      </div>
    </el-dialog>
    <el-dialog
      v-model="showServeModal"
      title="温馨提示"
      top="30vh"
      customClass="modalStyle"
      center
    >
      <div style="padding-bottom: 10px; text-align: center">
        <span style="font-size: 1.2rem; font-weight: bold"
          >请使用微信扫码添加客服</span
        >
      </div>
      <div class="scanModal">
        <img src="../style/img/serve.jpg" alt="" />
      </div>
    </el-dialog>
    <!-- 登录 -->
    <div v-show="showLoginCode" @click="cancelShowLoginCode">
      <div class="loginImg">
        <div class="scene">
          <img src="../style/img/qrcode_for_gh_c00a4ed6fe39_258.jpg" />
          <span>验证码: {{ loginCode }}</span>
          <p>
            微信扫码关注公众号回复验证码即可登录<br />登录成功后，您的查询记录可在公众号随时查看
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="showModal"
      :title="showModalTitle"
      width="950px"
      :before-close="modalClose()"
    >
      <el-table :data="arr.data" style="width: 100%" max-height="620">
        <template v-if="currentType !== 'payOrder'">
          <el-table-column prop="create_time" label="查询时间" width="150" />
          <el-table-column prop="product_name" label="查询类型" width="150" />
          <el-table-column prop="sn" label="序列号" width="150" />
          <el-table-column prop="price" label="价格" width="150" />
          <!-- <el-table-column prop="order_status" label="订单状态" width="150" /> -->
          <el-table-column align="right" width="150">
            <template #header v-if="userInfo.data.id === 'mp_4052'">
              <el-input
                v-model="searchInput"
                size="small"
                placeholder="Type to search"
                @keydown.enter="onBlur"
                type="number"
              />
            </template>
            <template v-slot="scope">
              <el-button size="small" @click="handResult(scope.row.result_id)"
                >查看结果</el-button
              >
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            prop="create_time"
            label="充值时间"
          ></el-table-column>
          <el-table-column prop="total_fee" label="充值金额"
            ><template mplate v-slot="scope">{{
              scope.row.total_fee / 100
            }}</template></el-table-column
          >
          <el-table-column prop="free_fee" label="赠送金额">
            <template mplate v-slot="scope">{{
              scope.row.free_fee / 100
            }}</template>
          </el-table-column>
        </template>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :default-current-page="1"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </el-dialog>
  </nav>
</template>

<script setup>
import { getTagInfo } from '../utils/a'
import { post, get } from '../utils/request'
import { getTimeStamp, kay1686 } from '../utils/common'
import { ElMessageBox, ElLoading, ElMessage } from 'element-plus'
import { ref, defineExpose, reactive } from 'vue'
import { useStore } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import { getDate } from '../utils/date'
// 引入BAAS
// var BaaS = require('minapp-sdk')
// BaaS.init('cfba74ef5fa0bc48c0ae')

var store = useStore()
var searchInput = ref('')

// **************************************** 支付 *****************、
var orderInput = ref('')
var checkOrderModal = ref(false)
var PayDialog = ref(false)
var tradeNo = ref('')
// var money = reactive(
//   [{
//     jiage: '1',
//     zengsong: '0',
//     vip: false,
//     borderStyle: 'moneyChecked'
//   },
//   {
//     jiage: '5',
//     zengsong: '0',
//     vip: false
//   },
//   {
//     jiage: '10',
//     zengsong: '2',
//     vip: true
//   },
//   {
//     jiage: '20',
//     zengsong: '5',
//     vip: true
//   },
//   {
//     jiage: '50',
//     zengsong: '15',
//     vip: true
//   },
//   {
//     jiage: '100',
//     zengsong: '30',
//     vip: true
//   }
//   ]
// )
var money = reactive({ data: '' })
var moneyStyle = reactive(
  [
    { name: '微信支付', ename: 'wechat', img: require('../style/img/wechat.png'), borderStyle: 'moneyChecked' },
    { name: '支付宝支付', ename: 'alipay', img: require('../style/img/alipay.png') }
  ]
)
var moneyIndex = 0
var moneyStyleIndex = 0
var free = ref('')
var price = ref('')
var qrDialog = ref(false)
var qrValue = ref('')
var payDialogTitle = ref('')
var payType = ref('')
// ***************************************************************
var showLoginCode = ref(false)
var showLoginButton = ref(true)
var showSimModal = ref(false) // 是否显示领取流量卡modal
var showServeModal = ref(false) // 是否显示客服modal
var loginCode = ref()
var navbarList = [
  { name: '苹果查询', ename: 'iOS' },
  { name: '安卓查询', ename: 'Android' },
  { name: '解锁资格', ename: 'jiesuo' },
  { name: '会员查询', ename: 'free' }
]
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`
const userInfo = reactive({ data: '' })
var limit = 0
// ***************************** 分页 *********************************************
var showModal = ref(false)
var isShowModal = true
var arr = reactive({ data: [] })
var arrCache = []
var total = ref(0)
var currentPage = ref(1)
var currentType = ref('')
var showModalTitle = ref('')

// const getDeposit = async () => {
//   const data = {
//     timestamp: getTimeStamp()
//   }
//   data.token = kay1686(data)
//   var res = await get('/getDeposit', data)
//   console.log(res)
//   money.data = res.data
//   for (let i = 0; i < money.data.length; i++) {
//     money.data[i].borderStyle = ''
//   }
//   money.data[0].borderStyle = 'moneyChecked'
//   console.log(money)
// }
// getDeposit()

const textClick = (e) => {
  const str = { e }
  const info = str.e.attributes.info.nodeValue
  const content = getTagInfo(info)
  console.log(content)
  console.log(e)
  ElMessageBox.alert(content, '说明', {
    confirmButtonText: 'OK'
  })
}
window.textClick = textClick

// 历史搜索查询
const onBlur = () => {
  const order = searchInput.value / 1686
  getHistory(order)
}

// cloud登录
// const loginUser = () => {
//   BaaS.auth.login({ email: '757625008@qq.com', password: '89692628' }).then(user => {
//     console.log(user)
//   }).catch(err => {
//     // HError
//     console.log(err)
//   })
// }
// loginUser()

// 获取充值查询记录
const handleClickGetOrder = async (size, type = '') => {
  console.log(type)
  const loading = showLoading('加载中')
  showModal.value = true
  var res
  if (isShowModal) {
    if (type === 'payOrder') {
      currentType.value = 'payOrder'
      showModalTitle.value = '充值记录查询'
      console.log(currentType.value)
      const data = {
        limit: size - 1,
        timestamp: getTimeStamp(),
        user_id: userInfo.data.id,
        pay_type: 'wechat'
      }
      data.token = kay1686(data)
      res = await post('/getPayOrder', data)
    } else {
      currentType.value = ''
      showModalTitle.value = '历史结果查询'
      const data = {
        limit: size - 1,
        timestamp: getTimeStamp(),
        user_id: 4
      }
      data.token = kay1686(data)
      res = await post('/getHistoryPage', data)
    }
    console.log(res)
    loading.close()
    if (res.code === 200) {
      if (limit === 0) {
        arr.data = res.data.data
        total.value = res.data.total
        arrCache[1] = res.data.data
      } else {
        arr.data = res.data
        arrCache[size] = res.data
      }
      limit += 1
    } else {
    }
  }
}

const handleCurrentChange = (size) => {
  console.log(size)
  console.log('handleCurrentChange')
  currentPage.value = size
  if (arrCache[size] === undefined) {
    const loading = showLoading()
    setTimeout(() => {
      loading.close()
      console.log(currentType.value)
      handleClickGetOrder(size, currentType.value)
    }, 1500)
  } else {
    arr.data = arrCache[size]
  }
}

const modalClose = (e) => {
  limit = 0
  isShowModal = true
  currentPage.value = 1
  arrCache = []
}

// 查询结果
const handResult = (e) => {
  getHistory(e)
}

// 发起支付
const createOrder = () => {
  qrValue.value = ''

  const params = {
    totalCost: money.data[moneyIndex].amount / 100,
    merchandiseDescription: '玩机查询余额充值',
    merchandiseSnapshot: money.data[moneyIndex]
  }

  if (moneyStyle[moneyStyleIndex].ename === 'wechat') {
    params.gatewayType = 'weixin_tenpay_native'
    // 微信支付
    BaaS.payment.payWithWechat(params).then(res => {
      /**
       * success.
       * res.data.code_url 用来生成二维码
       * res.data.trade_no 用来查询支付状态
       */
      payDialogTitle.value = '请使用微信扫码支付'
      qrValue.value = res.data.code_url
      price.value = money.data[moneyIndex].amount / 100
      free.value = money.data[moneyIndex].handsel / 100
      qrDialog.value = true
      PayDialog.value = false
      tradeNo.value = res.data.trade_no
      payType.value = 'wechat'
      console.log(res)
      var intervalNum = 0
      const interval = setInterval(() => {
        if (qrDialog.value === true) {
          if (intervalNum < 10) {
            intervalNum++
            checkOrder(interval)
          } else {
            qrDialog.value = false
            clearInterval(interval)
          }
        } else {
          clearInterval(interval)
        }
      }, 2000)
    }, err => {
      // 未完成用户授权或发生网络异常等
      console.log(err)
    })
  } else {
    params.gatewayType = 'alipay_page'
    // 支付宝支付
    BaaS.payment.payWithAlipay(params).then(res => {
      /**
       * success.
       * res.data.payment_url 用来跳转到支付页面
       * res.data.trade_no 用来查询支付状态
       */
      window.open(res.data.payment_url)
      payDialogTitle.value = '请确认是否完成支付'
      price.value = money.data[moneyIndex].amount / 100
      free.value = money.data[moneyIndex].handsel / 100
      qrDialog.value = true
      PayDialog.value = false
      tradeNo.value = res.data.transaction_no
      payType.value = 'alipay'
      console.log(res)
      var intervalNum = 0
      const interval = setInterval(() => {
        if (qrDialog.value === true) {
          if (intervalNum < 30) {
            intervalNum++
            checkOrder(interval)
          } else {
            qrDialog.value = false
            clearInterval(interval)
          }
        } else {
          clearInterval(interval)
        }
      }, 2000)
    }, err => {
      // 未完成用户授权或发生网络异常等
      console.log(err)
    })
  }
}

const closeQrDialog = () => {
  qrDialog.value = false
}

const PayDialogClose = () => {
  for (let i = 0; i < moneyStyle.length; i++) {
    moneyStyle[i].borderStyle = ''
  }
  moneyStyleIndex = 0
  moneyStyle[0].borderStyle = 'moneyChecked'
}

// 根据手动输入的订单号进行验证充值
const checkInputOrder = async () => {
  const timestampCheckOrder = localStorage.getItem('timestamp_checkOrder')
  console.log(timestampCheckOrder)
  console.log(getTimeStamp())
  if (timestampCheckOrder === null) {
    localStorage.setItem('timestamp_checkOrder', 0)
  }
  if ((getTimeStamp() - timestampCheckOrder) > 60) {
    if (orderInput.value !== '') {
      const loading = showLoading('验证中')
      const data = {
        timestamp: getTimeStamp(),
        trade_no: orderInput.value
      }
      data.token = kay1686(data)
      const res = await post('/checkMpOrder', data)
      console.log(res)
      loading.close()
      localStorage.setItem('timestamp_checkOrder', getTimeStamp())
      if (res.code === 200) {
        ElMessageBox.alert('该订单已完成充值', '温馨提示', {
          confirmButtonText: 'OK'
        })
      } else {
        cloudCheckOrder()
      }
      console.log()
    } else {
      ElMessageBox.alert('支付单号不能为空', '温馨提示', {
        confirmButtonText: 'OK'
      })
    }
  } else {
    ElMessageBox.alert('操作频繁请1分钟后再试', '温馨提示', {
      confirmButtonText: 'OK'
    })
  }
}

const cloudCheckOrder = () => {
  const loading = showLoading('很快')
  var order = new BaaS.Order()
  var data = {}
  if (moneyStyle[moneyStyleIndex].ename === 'wechat') {
    data = { trade_no: orderInput.value }
  } else if (moneyStyle[moneyStyleIndex].ename === 'alipay') {
    data = { transaction_no: orderInput.value }
  }
  order.getOrderList(data).then(res => {
    loading.close()
    console.log(res)
    const orderInputValue = orderInput.value
    orderInput.value = ''
    if (res.data.objects[0].status === 'success') {
      console.log('支付成功')
      var str = res.data.objects[0].merchandise_snapshot.replace(/'/g, '"').toLowerCase()
      console.log(str)
      var merchandiseSnapshot = JSON.parse(str)
      console.log(merchandiseSnapshot)
      depositMoney(orderInputValue, moneyStyle[moneyStyleIndex].ename, merchandiseSnapshot)
    } else {
      ElMessageBox.alert('未找到该订单、或该订单未进行支付、或支付方式选择不正确', '温馨提示', {
        confirmButtonText: 'OK'
      })
    }
  }).catch(e => {
    console.log(e)
    // HError 对象
    ElMessageBox.alert('未找到该订单、或该订单未进行支付、或支付方式选择不正确', '温馨提示', {
      confirmButtonText: 'OK'
    })
  })
}

// 验证订单是否完成支付
const checkOrder = (interval, click) => {
  var order = new BaaS.Order()
  var data = {}
  if (payType.value === 'wechat') {
    data = { trade_no: tradeNo.value }
  } else if (payType.value === 'alipay') {
    data = { transaction_no: tradeNo.value }
  }
  order.getOrderList(data).then(res => {
    // success
    console.log(res)
    if (res.data.objects[0].status === 'success') {
      console.log('支付成功')
      depositMoney(tradeNo.value, payType.value)
      clearInterval(interval)
    }
    if (click) {
      qrDialog.value = false
      ElMessageBox.alert('充值失败，请重新充值', '错误', {
        confirmButtonText: 'OK'
      })
    }
  }).catch(e => {
    console.log(e)
    // HError 对象
  })
}
// 充值
const depositMoney = async (tradeNo, payType, merchandiseSnapshot = null) => {
  console.log(merchandiseSnapshot)
  const loading = showLoading('正在充值')
  var isVip = (userInfo.data.is_vip === 0 && money.data[moneyIndex].vip === 1) ? 1 : 0
  try {
    var currentTimestamp = getTimeStamp()
    const data = {
      is_vip: isVip,
      timestamp: currentTimestamp,
      user_id: userInfo.data.id,
      trade_no: tradeNo,
      total_fee: money.data[moneyIndex].amount / 100,
      free_fee: money.data[moneyIndex].handsel / 100,
      type: 'web_' + payType
    }
    if (merchandiseSnapshot !== null) {
      console.log(merchandiseSnapshot)
      data.isVip = (userInfo.data.is_vip === 0 && merchandiseSnapshot.vip === 1) ? 1 : 0
      data.total_fee = merchandiseSnapshot.amount / 100
      data.free_fee = merchandiseSnapshot.handsel / 100
    }
    data.token = kay1686(data)
    const res = await post('/pay', data)
    console.log(res)
    loading.close()
    if (res.code === 200) {
      var addMoney = (Number(data.total_fee) + Number(data
        .free_fee)) * 100
      console.log(addMoney)
      userInfo.data.money += addMoney
      if (isVip) {
        console.log('isvip')
        userInfo.data.is_vip = true
        // localStorage.removeItem('FreeNumData') // 重新计算免费查询次数
      }
      store.commit('changeUserInfo', userInfo.data)
      console.log(store.state.userInfo)
      console.log(userInfo.data)
      qrDialog.value = false
      ElMessageBox.alert('充值成功', '温馨提示', {
        confirmButtonText: '好的',
        customClass: 'ElMessageBoxStyle'
      })
    }
  } catch (e) {

  }
}

// 选择支付金额
const selectPayMoney = (e) => {
  const index = e
  console.log(e)
  for (let i = 0; i < money.data.length; i++) {
    money.data[i].borderStyle = ''
  }
  money.data[index].borderStyle = 'moneyChecked'
  moneyIndex = index
  console.log(money)
}

// 选择支付方式
const selectPayStyle = (e) => {
  console.log(e)
  const index = e
  for (let i = 0; i < moneyStyle.length; i++) {
    moneyStyle[i].borderStyle = ''
  }
  moneyStyleIndex = index
  moneyStyle[index].borderStyle = 'moneyChecked'
}

const goAnchor = (e) => {
  console.log(e)
  var href = e.target.dataset.href
  var index = parseInt(e.target.dataset.index)
  console.log(index)
  if (index === 0) {
    console.log(true)
    document.querySelector('#' + href).scrollIntoView(true)
    setTimeout(() => {
      document.body.scrollIntoView()
    }, 1000)
  } else if (index === navbarList.length - 1) {
    document.querySelector('#' + href).scrollIntoView({ block: 'end' })
  } else {
    document.querySelector('#' + href).scrollIntoView({ block: 'center' })
  }
}

const cancelShowLoginCode = () => {
  console.log('cancelShowLoginCode')
  showLoginCode.value = false
}

const handleClickSim = () => {
  showSimModal.value = true
}

// 登录
const Login = () => {
  console.log(localStorage.getItem('userId'))
  console.log(localStorage.getItem('openId'))
  if (localStorage.getItem('userId') && localStorage.getItem('openId') && localStorage.getItem('loginTime')) {
    if (getDate() - localStorage.getItem('loginTime') > 86400) {
      showLoginButton.value = true
      localStorage.removeItem('userId')
      localStorage.removeItem('openId')
    } else {
      showLoginButton.value = false
      getMpUserInfo()
    }
  } else {
    console.log(1)
    showLoginButton.value = true
  }
}

// 点击登录按钮发送loginCode
const handleClickLogin = async () => {
  loginCode.value = getLoginCode()
  var currentTimestamp = getTimeStamp()
  const data = {
    loginCode: loginCode.value,
    timestamp: currentTimestamp
  }
  data.token = kay1686(data)
  try {
    const res = await post('/webLogin', data)
    console.log(res.code)
    if (res.code === 200) {
      showLoginCode.value = true
      var intervalNum = 0
      const interval = setInterval(() => {
        if (intervalNum < 20) {
          intervalNum++
          getWebLoginStatus(loginCode.value, interval)
        } else {
          showLoginCode.value = (false)
          clearInterval(interval)
          ElMessageBox.alert('登录超时，请重新点击登录 ', '温馨提示', {
            confirmButtonText: 'OK'
          })
        }
      }, 2000)
    }
  } catch (e) {
  }
}

// 从服务器上获取登录状态
const getWebLoginStatus = async (loginCode, interval) => {
  var currentTimestamp = getTimeStamp()
  const data = {
    loginCode: loginCode,
    timestamp: currentTimestamp
  }
  data.token = kay1686(data)
  try {
    const res = await post('/webloginstatus', data)
    console.log(res)
    if (showLoginCode.value === true) {
      if (res.code === 200) {
        localStorage.setItem('userId', 'mp_' + res.data.userId)
        localStorage.setItem('openId', res.data.openid)
        localStorage.setItem('loginTime', getDate())
        clearInterval(interval)
        showLoginCode.value = (false)
        getMpUserInfo()
      }
    } else {
      clearInterval(interval)
    }
  } catch (e) {
  }
}

// 获取用户信息
const getMpUserInfo = async () => {
  const loading = showLoading('正在登录')
  const openId = localStorage.getItem('openId')
  console.log(openId)
  if (openId) {
    try {
      var currentTimestamp = getTimeStamp()
      const data = {
        openid: openId,
        timestamp: currentTimestamp
      }
      data.token = kay1686(data)
      const res = await post('/getMpUserInfo', data)
      console.log(res)
      loading.close()
      if (res.code === 200) {
        showLoginButton.value = false
        store.commit('changeUserInfo', res.data)
        userInfo.data = store.state.userInfo
        console.log(store.state.userInfo)
        ElMessage({
          message: '您已成功登录',
          type: 'success'
        })
      } else {
        localStorage.removeItem('userId')
        localStorage.removeItem('openId')
        ElMessageBox.alert('登录失败，请稍后再试', '登录错误', {
          confirmButtonText: 'OK'
        })
      }
    } catch (e) {
      ElMessageBox.alert('登录失败，请稍后再试', '登录错误', {
        confirmButtonText: 'OK'
      })
    }
  }
}

const outLogin = (reload) => {
  ElMessageBox.alert('确认退出登录', '温馨提示', {
    confirmButtonText: '确定退出',
    customClass: 'ElMessageBoxStyle',
    showCancelButton: true,
    cancelButtonText: '取消',
    showClose: false,
    callback: (e) => {
      console.log(e)
      if (e === 'confirm') {
        localStorage.removeItem('userId')
        localStorage.removeItem('openId')
        showLoginButton.value = true
        userInfo.data = ''
        location.reload()
      }
    }
  })
}

// 生成loginCode
const getLoginCode = () => {
  const num = math(1, 100000)
  var str = 'wj'
  if (num.toString().length < 6) {
    const zero = 6 - num.toString().length
    for (var i = 0; i < zero; i++) {
      str += '0'
    }
    return (str += num)
  } else {
    return (str += num)
  }
}

// 生成随机数
const math = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

const handleClickMoneyPay = () => {
  PayDialog.value = true
}

const showLoading = (text) => {
  var loading = ElLoading.service({
    text: text,
    spinner: svg,
    svgViewBox: '-10, -10, 50, 50',
    background: 'rgba(0, 0, 0, 0.8)'
  })
  return loading
}

const getHistory = async (historyId) => {
  const loading = ElLoading.service({
    text: '还差一点点',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  const data = {
    id: historyId,
    timestamp: getTimeStamp()
  }
  data.token = kay1686(data)
  try {
    const result = await get('/history', data)
    loading.close()
    console.log(result)
    if (result.code === 200) {
      var content = result.data.content
        .replace(/<table([\s\w"=\\/\\.:;]+)((?:(style="[^"]+")))/ig, '<table')
        .replace(/<table([\s\w"=\\/\\.:;]+)((?:(class="[^"]+")))/ig, '<table')
        .replace(/<table>/ig, '<table class="table">')

      content = content
        .replace(/<th([\s\w"=\\/\\.:;]+)((?:(style="[^"]+")))/ig, '<th')
        .replace(/<th([\s\w"=\\/\\.:;]+)((?:(class="[^"]+")))/ig, '<th')
        .replace(/<th>/ig, '<th class="table-th">')

      content = content
        .replace(/<td([\s\w"=\\/\\.:;]+)((?:(style="[^"]+")))/ig, '<td')
        .replace(/<td([\s\w"=\\/\\.:;]+)((?:(class="[^"]+")))/ig, '<td')
        .replace(/<td>/ig, '<td class="table-td">')

      content = content
        .replace(/<a/ig, '<a class="table-a" onclick="textClick(this)"')

      open(content)
    } else {
      ElMessageBox.alert('获取记录超时，请稍后再试', '温馨提示', {
        confirmButtonText: 'OK'
      })
    }
  } catch (e) {
    console.log(e)
    ElMessageBox.alert('获取记录超时，请稍后再试', '温馨提示', {
      confirmButtonText: 'OK'
    })
  }
}

const open = (str) => {
  ElMessageBox.alert(str, '查询成功', {
    dangerouslyUseHTMLString: true,
    confirmButtonText: 'OK',
    customClass: 'ElMessageBoxStyle',
    callback: (action) => {
    }
  })
}

Login()

// 把子组件方法暴露出去
defineExpose({
  handleClickLogin,
  handleClickMoneyPay,
  getHistory
})

</script>
<style scoped>
#navbar a {
  cursor: pointer;
}

.modalStyle img {
  width: 200px;
  height: 200px;
}
.loginImg {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.loginImg .scene {
  background: #fff;
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  color: black;
}

.loginImg img {
  width: 350px;
  height: 350px;
  display: block;
  border-radius: 10px;
}

.loginImg span {
  line-height: 2rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.loginImg p {
  line-height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.navbar-left {
  display: flex;
  flex-direction: row;
}
.navbar-left ul {
  margin-left: 30px;
}
.navbar-left li {
  font-size: 17px;
}

.navbar-right {
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.navbar-right a {
  color: rgb(173, 173, 173) !important;
}

.navbar-right a:hover {
  background: none !important;
  color: white !important;
}

/* ****************************************** 充值中心  ************************************************* */
.moneyView {
  background: white;
  width: 100%;
  border-radius: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px;
}

.moneyView-money {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
}

.money {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  /* background: #f5f8ff; */
  /* background: black; */
  color: #337afa;
  height: 80px;
  line-height: 18px;
  margin: 6px;
  position: relative;
  cursor: pointer;
  border: 2px solid #d6d6d6;
  transition: border-color 0.3s linear;
  font-weight: bold;
}

#navbar >>> .el-dialog__body {
  padding: 10px !important;
}

#navbar >>> .el-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.money .vip {
  background: rgb(220, 20, 60);
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.money-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.moneyView-title {
  width: 100%;
  margin: 5px;
  text-align: left;
}

.payStyleView {
  width: 100%;
  text-align: left;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.payStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
}

.pay {
  border: 2px solid #d6d6d6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 6px;
  padding: 5px;
  width: 33%;
}

.moneyChecked {
  border-color: #337afa;
}

.pay img {
  width: 25px !important;
  height: 25px !important;
  padding: 2px !important;
}

.pay span {
  padding: 2px;
}

.payStyleButton {
  margin-top: 60px;
  width: 150px;
}
/* ****************************************** 充值中心  ************************************************* */

/* ****************************************** 二维码弹窗  ************************************************* */

.qrView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.qrCode {
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.qrOrder {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
  width: 200px;
}

.qrOrder p {
  margin: 0;
  margin-bottom: 24px;
}

.qrButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 0;
}

/* ****************************************** 二维码弹窗  ************************************************* */

.historyModalClass >>> .el-dialog__body {
  height: 500px !important;
}

@media screen and (max-width: 768px) {
  .loginImg .scene {
    width: 300px;
  }

  .loginImg img {
    width: 300px;
    height: 300px;
  }

  #navbar ul li a {
    font-size: 0.9rem;
    /* padding: 0;
    padding-right: 10px; */
  }

  #navbar {
    display: flex;
    flex-direction: column;
  }

  #navbar ul {
    padding-top: 10px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 568px) {
  #navbar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #navbar ul {
    padding: 10px 0px;
    flex-wrap: wrap;
  }
  #navbar li {
    padding: 5px;
  }
}
</style>

<style lang="sass">
.modalStyle
  width: 290px !important
.scanModal
  text-align: center
</style>
