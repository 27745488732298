<template>
  <Navbar ref="navbar"></Navbar>
  <router-view
    @login="navbarLogin"
    @deposit="navbarDeposit"
    @getHistory="getHistory"
  />
  <Footer></Footer>
</template>

<script setup>
import Navbar from '../src/components/Navbar'
import Footer from '../src/components/Footer'
import { ref } from 'vue'

const navbar = ref(null)
const navbarLogin = () => {
  navbar.value.handleClickLogin()
}

const navbarDeposit = () => {
  navbar.value.handleClickMoneyPay()
}

const getHistory = (e) => {
  navbar.value.getHistory(e)
}

</script>

<style>
@import url("./style/css/style.css");
@import url("./style/css/mobile.css");
@import url("./style/css/widescreen.css");
/* @import url("./style/css/font-awesome.min.css"); */
* {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

/* * ::-webkit-scrollbar {
  width: 0 !important;
} */

.el-popup-parent--hidden {
  padding-right: 0px !important;
  overflow: auto !important;
}

.table-th {
  border: 1px solid #c0c0c0;
  width: 40%;
  padding: 10px 0;
}
.table-th .img {
  width: 100px;
  height: 100px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.table-td {
  border: 1px solid #c0c0c0;
  width: 40%;
  padding: 10px 0;
}

.table-a {
  color: white;
  padding: 10px;
  margin-right: 10px;
  background: #0081ff;
  text-decoration: none !important;
  font-size: 12px;
}
</style>
