<template>
  <section id="what" class="bg-light py-1">
    <div class="container">
      <h2 class="m-heading text-center">
        <span class="text-primary">苹果查询常见问题</span>
      </h2>
      <div class="items">
        <div class="item">
          <div class="">
            <h3>什么是GSX查询？</h3>
            <p>
              GSX（Global Service
              Exchange）激活策略，听上去好像十分高大上，其实它是苹果全球联网的售后查询渠道
            </p>
          </div>
        </div>
        <div class="item">
          <div class="">
            <h3>GSX可以查到什么？</h3>
            <p>
              通过 序列号 或 imei
              可以查询到一部iPhone的基础出厂信息，比如颜色、内存容量、是否官换机、是否BS机、是否样机、黑机、有无网络锁、有无运营商等30多项信息。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="">
            <h3>如何进行GSX查询？</h3>
            <p>
              使用微信关注官方公众号：<span style="color: red">AppleWanJi</span
              >, 或使用<span style="color: red">微信扫描二维码</span
              >，即可进行查询
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container__img">
      <img src="../../style/img/qrcode_for_gh_c00a4ed6fe39_258.jpg" alt="" />
    </div>
  </section>
</template>

<script setup>
</script>
<style scoped>
</style>
