import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
    // meta: {
    //   title: '首页',
    //   content: {
    //     keywords: '关键字1，关键字2',
    //     description: '描述内容描述内容描述内容描述内容描述内容描述内容描述内容'
    //   }
    // }
  },
  {
    path: '/article',
    name: 'Article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Article/Article.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.meta.content) {
  //   const head = document.getElementsByTagName('head')
  //   const meta = document.createElement('meta')
  //   document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
  //   document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
  //   meta.content = to.meta.content
  //   head[0].appendChild(meta)
  // }
  // // 路由发生变化修改页面title
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  // localStorage.setItem('isiphone',flag)
  localStorage.setItem('ismobile', flag ? 1 : 0)
  // alert(navigator.userAgent)
  const goUrl = flag ? 1 : 0
  if (goUrl) {
    location.href = 'https://m.gsxchaxun.com'
  } else {
    next()
  }
})

export default router
