<template>
  <header id="home">
    <div class="home-content">
      <!-- <h1 class="l-heading">Apple GSX 查询</h1> -->
      <h1 class="l-heading">{{ title }}</h1>
      <div class="vipDateView" v-if="product.data.free_num">
        <text>今日剩余免费查询次数：{{ freeNum }}</text>
      </div>
      <div class="home-inputView">
        <input
          type="text"
          :placeholder="inputValue.placeholder"
          v-model="input"
          :disabled="inputValue.disabled"
        />
        <Validate
          @onConfirmSuccess="setConfirmSuccess"
          ref="validate"
        ></Validate>
        <div style="margin-top: 30px" v-if="product.data.type === 'page'">
          <el-alert
            title="请使用微信扫码成功后，选择 会员查询 - 全品牌山寨机鉴定"
            type="success"
            effect="dark"
            :closable="false"
          >
          </el-alert>
          <img
            style="padding: 20px"
            src="../../style/img/wechatapp.jpg"
            alt=""
          />
        </div>
        <el-button
          v-else
          class="home-btn"
          type="primary"
          @click="handleSelectClick"
          :disabled="inputValue.buttonDisab"
          >查询</el-button
        >
      </div>
      <!-- <div class="products">
        <Products @clickProduct="getProduct"></Products>
      </div> -->
      <!-- <div class="example" v-show="showExample">
        <Example :text="text" @clickProduct="getProduct"></Example>
      </div> -->
      <el-dialog
        v-model="centerDialogVisible"
        title="温馨提示"
        width="300px"
        top="30vh"
        customClass="modalStyle"
        center
      >
        <span>{{ tips }}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">了解</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog v-model="selectTipsModal" title="温馨提示" width="30%">
        <span style="font-size: 15px">{{ selectTipContent }}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="selectTipsModal = false">取消</el-button>
            <el-button
              type="primary"
              v-if="selectTipButton == true"
              @click="select"
              >确定</el-button
            >
            <el-button type="primary" v-else @click="deposit">充值</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog
        v-model="showMinappModal"
        close-on-click-modal="false"
        :before-close="true"
        :show-close="false"
        title="温馨提示"
        top="30vh"
        customClass="modalStyle"
        center
      >
        <div style="padding-bottom: 10px; text-align: center">
          <span style="font-size: 1.2rem; font-weight: bold"
            >网站正在维护，请使用微信扫码查询</span
          >
        </div>
        <div class="" style="height:230px;display:flex;justify-content:center">
          <img style="width:225px;height:250px" src="../../style/img/minapp.jpg" alt="" />
        </div>
      </el-dialog>
    </div>
  </header>
  <Section></Section>
</template>

<script setup>
import { ref, defineEmits, reactive, watch } from "vue";
import { get, post } from "../../utils/request";
import { getTimeStamp, kay1686 } from "../../utils/common";
import { getCurrentDate } from "../../utils/date";
import { ElMessageBox, ElLoading, ElMessage } from "element-plus";
import { useStore } from "vuex";
import Section from "./Section";
import Validate from "../../components/Validate";
import Products from "../../components/Products";
import Example from "../../components/Example";

const store = useStore();
const showMinappModal = ref(true);
const input = ref("");
var tips = ref("");
var centerDialogVisible = ref(false);
// var hTimeStamp = localStorage.getItem('timestamp') ? localStorage.getItem('timestamp') : 0
var confirmSuccess = false;
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`;
var product = reactive({ data: "" });
var inputValue = reactive({
  disabled: false,
  placeholder: "请输入苹果12位序列号",
  buttonDisab: false,
});
var title = ref("苹果序列号查询");
var text = ref("");
var showExample = ref(false); // 是否展示示例页
var freeNum = ref(0);
var maxlength = ref(0);
var selectTipContent = ref("");
var selectTipButton = ref(false);
var selectTipsModal = ref(false);

watch(input, () => {
  input.value = input.value.toUpperCase().replace(/(^\s*)|(\s*$)/g, "");
});

const emit = defineEmits(["login", "deposit", "getHistory", "checkLogin"]);
const checkLogin = () => {
  if (localStorage.getItem("userId") && localStorage.getItem("openId")) {
    return true;
  } else {
    emit("login");
    return false;
  }
};

const deposit = () => {
  selectTipsModal.value = false;
  emit("deposit");
};

const getHistory = (e) => {
  emit("getHistory", e);
};
// 点击查询
const handleSelectClick = () => {
  if (checkLogin()) {
    const currentTimeStamp = Date.parse(new Date()) / 1000;
    const timeStamp = JSON.parse(localStorage.getItem("timeStamp"));
    if (timeStamp !== null) {
      if (currentTimeStamp - timeStamp < 86400) {
        ElMessageBox.alert("网页查询每日只有一次，请前往微信公众号进行查询", {
          confirmButtonText: "OK",
        });
        return;
      }
    }
    if (confirmSuccess) {
      resetConfirmSuccess();
      if (checkSerial()) {
        localStorage.setItem("timeStamp", currentTimeStamp);
        select();
      } else {
      }
    } else {
      ElMessage.error("请拖动滑块验证");
    }
  }
  // if (confirmSuccess) {
  //   const userInfo = store.state.userInfo
  //   console.log(userInfo.money)
  //   console.log(product.data)
  //   if (checkSerial()) {
  //     var serial = input.value
  //     if (product.data.free_num !== 0 && checkFreeNum()) {
  //       selectTipContent.value = '今日剩余免费查询次数：' + freeNum.value + ' 次，请确认您输入的' + inputValue.typeTips + '：【' + serial +
  //         '】，是否正确，输入错误也会扣除次数的哦！'
  //       selectTipButton.value = true
  //       // that.is_free = true
  //     } else if (userInfo.money >= (product.data.price * 100)) {
  //       if (product.data.price > 0) {
  //         checkUserMoney(userInfo, product.data.price).then(val => {
  //           if (val === false) {
  //             selectTipContent.value = '余额不足请充值后再试'
  //             selectTipButton.value = false
  //             ElMessageBox.alert('余额不足请充值后再试', '温馨提示', {
  //               confirmButtonText: 'OK',
  //               callback: () => {
  //                 location.reload()
  //               }
  //             })
  //           } else {
  //             selectTipContent.value = '总余额' + (userInfo.money / 100) + '个J币，本次将扣除 ' +
  //               (product.data.price) + ' 个 请确认您输入的' + inputValue.typeTips + '：【' + serial + '】，是否正确，查询错误也会扣款哦！'
  //           }
  //         })
  //       } else {
  //         selectTipContent.value = '请确认您输入的' + inputValue.typeTips + '：【' + serial + '】，是否正确'
  //       }
  //       selectTipButton.value = true
  //     } else {
  //       selectTipContent.value = '总余额 ' + (userInfo.money / 100) + ' 个J币，本次将扣除 ' + (product.data.price) + ' 个 , 余额不足请充值后再试'
  //       selectTipButton.value = false
  //     }
  //     selectTipsModal.value = true
  //     resetConfirmSuccess()
  //   }
  // } else {
  //   ElMessage.error('请拖动滑块验证')
  // }
};

const getProduct = async (e) => {
  // resetConfirmSuccess();
  console.log(1);
  // const loading = showLoading();
  const data = {
    id: 1,
    timestamp: getTimeStamp(),
  };
  data.token = kay1686(data);
  try {
    const result = await get("/product", data);
    console.log(result);
    if (result.data) {
      product.data = result.data;
      title.value = result.data.cname;
      inputValue.disabled = false;
      const isType = isTypes(result.data.type);
      inputValue.placeholder = isType.tips;
      inputValue.tips = isType.placeholder;
      inputValue.typeTips = isType.typeTips;
      inputValue.buttonDisab = false;
      text.value = result.data.content;
      showExample.value = true;
      if (result.data.free_num) {
        getFreeNumStrong(e);
      }
      loading.close();
    }
  } catch (e) {}
};

getProduct();

const checkUserMoney = async (userInfo, productPrice) => {
  var data = {
    timestamp: getTimeStamp(),
    user_id: userInfo.id,
    product_price: productPrice,
    from: "mp_wechat",
  };
  data.token = kay1686(data);

  try {
    const res = await post("/checkUserMoney", data);
    console.log(res);
    if (res.code === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

const checkFreeNum = () => {
  var isBlack;
  const userInfo = store.state.userInfo;
  if (userInfo.is_vip) {
    isBlack = freeNum.value > product.data.vip_num ? "true" : "false";
  } else {
    isBlack = freeNum.value > product.data.free_num ? "true" : "false";
  }
  console.log(isBlack);
  console.log(freeNum.value);
  console.log(product.data.vip_num);
  if (isBlack === "false") {
    if (freeNum.value > 0) {
      console.log(1);
      return true;
    } else {
      console.log(2);
      return false;
    }
  } else {
    setUserBlack(userInfo);
  }
};

const setUserBlack = async (userInfo) => {
  var data = {
    timestamp: getTimeStamp(),
    user_id: userInfo.id,
  };
  data.token = kay1686(data);
  const res = await post("/setUserBlack", data);
  if (res.code === 200) {
    console.log("isBlack");
    location.reload();
  }
};

const select = async () => {
  console.log("select");
  const userInfo = store.state.userInfo;
  const loading = showLoading("查询中");
  const data = {
    sn: input.value,
    timestamp: getTimeStamp(),
    trade_no: "WEB",
    product_id: 1,
    user_id: userInfo.id,
  };
  data.token = kay1686(data);

  try {
    const res = await post("/serial", data);
    console.log(res);
    console.log(res.data);
    // var res = {
    //   code: 0,
    //   data: 516651,
    //   message: 'success'
    // }
    loading.close();
    input.value = "";
    selectTipsModal.value = false;
    if (res.code === 302311 || res.code === 302312 || res.code === 302315) {
      if (product.data.free_num !== 0 && checkFreeNum()) {
        setFreeNumStrong(res.data, userInfo.id);
      } else {
        redUserMoney(userInfo, null);
      }
      if (res.code === 302315) {
        ElMessageBox.alert(
          "该设备为更换机，属于 Apple 售后换机的送修（原始）设备，并非更换后的活跃设备（官换机）。部分系商家自行维修、翻新，无保修。在官网查会显示：这是已更换产品的序列号",
          "结果",
          {
            confirmButtonText: "OK",
          }
        );
      } else {
        ElMessageBox.alert(
          "序列号错误或无效，请仔细核对是否正确后再进行查询！",
          "错误",
          {
            confirmButtonText: "OK",
          }
        );
      }
    } else if (res.code === 302313) {
      ElMessageBox.alert(
        "服务器繁忙，请稍后再试，如有疑问可以添加客服微信进行咨询",
        "结果",
        {
          confirmButtonText: "OK",
        }
      );
    } else if (res.code === 302314) {
      getHistory(res.data);
    } else if (res.code === 0) {
      if (product.data.free_num !== 0 && checkFreeNum()) {
        setFreeNumStrong(res.data, userInfo.id, true);
      } else if (product.data.price > 0) {
        redUserMoney(userInfo, res.data);
      } else {
        // 跳转到结果页
        console.log("查询成功");
        getHistory(res.data);
      }
    }
  } catch (e) {
    loading.close();
    console.log(e);
  }
};

const redUserMoney = async (userInfo, orderId) => {
  const loading = showLoading("获取结果中");
  if (product.data.price > 0) {
    var data = {
      timestamp: getTimeStamp(),
      product_price: product.data.price,
      user_id: userInfo.id,
      from: "mp_wechat",
    };
    data.token = kay1686(data);

    try {
      const res = await post("/redUserMoney", data);
      loading.close();
      var price = product.data.price * 100;
      userInfo.money = userInfo.money - price;
      store.commit("changeUserInfo", userInfo);
      if (res.code === 200) {
        console.log("查询成功");
        if (orderId) {
          getHistory(orderId);
        }
      }
    } catch (error) {}
  } else {
    return true;
  }
};

const setFreeNumStrong = (orderId, userInfoId, toResult = false) => {
  var FreeNumData = JSON.parse(localStorage.getItem("FreeNumData"));
  freeNum.value = freeNum.value - 1;
  const productId = userInfoId + "p" + product.data.id;
  console.log(productId);
  FreeNumData[userInfoId][productId].num =
    FreeNumData[userInfoId][productId].num - 1;
  localStorage.setItem("FreeNumData", JSON.stringify(FreeNumData));
  if (toResult === true) {
    console.log("查询成功");
    getHistory(orderId);
  } else {
    return true;
  }
};

const validate = ref(null);
const checkSerial = () => {
  console.log("handleSelectClick");
  var serial = input.value;
  if (serial.length !== 0) {
    const len = serial.length;
    const type = product.data.type;
    console.log(product.data);
    console.log(type);
    resetConfirmSuccess();
    console.log(len);
    if (type === "sn") {
      if (len >= 10 && len < 14) {
        return true;
      } else {
        showVisible(true, "您输入的不是有效序列号");
      }
    } else if (type === "imei") {
      if (len > 13 && len < 17) {
        return true;
      } else {
        showVisible(
          true,
          "您输入的不是有效imei,imei为纯数字。请注意imei号码中间不能有空格"
        );
      }
    } else if (type === "snorimei") {
      if (len >= 10 && len < 17) {
        return true;
      } else {
        showVisible(true, "您输入的不是有效序列号或imei");
      }
    } else if (type === "model") {
      if (len > 7 && len < 12) {
        return true;
      } else {
        showVisible(true, "您输入的不是有效的型号");
      }
    }
  } else {
    showVisible(true, "输入框不能为空！");
    return false;
  }
};

const showVisible = (show, content) => {
  centerDialogVisible.value = show;
  tips.value = content;
};

const setConfirmSuccess = (e) => {
  console.log(e);
  if (e === true) {
    confirmSuccess = true;
  }
};

const resetConfirmSuccess = () => {
  validate.value.setConfirmSuccess();
  confirmSuccess = false;
};

const showLoading = (text = "加载中") => {
  var loading = ElLoading.service({
    text: text,
    spinner: svg,
    svgViewBox: "-10, -10, 50, 50",
    background: "rgba(0, 0, 0, 0.8)",
  });
  return loading;
};

const isTypes = (type) => {
  console.log(type);
  if (type === "imei") {
    maxlength.value = 15;
    const array = {
      tips: "请输入你的IMEI",
      placeholder: "",
      typeTips: "IMEI",
    };
    return array;
  } else if (type === "sn") {
    maxlength.value = 12;
    const array = {
      tips: "请输入你的序列号",
      placeholder: "",
      typeTips: "序列号",
    };
    return array;
  } else if (type === "snorimei") {
    maxlength.value = 15;
    const array = {
      tips: "请输入你的序列号或IMEI",
      placeholder: "",
      typeTips: "序列号/IMEI",
    };
    return array;
  } else if (type === "model") {
    maxlength.value = 10;
    const array = {
      tips: "请输入你的型号",
      placeholder: "例:NQ9Y2CH/A",
      typeTips:
        "型号（这里的型号不是指手机型号，而是手机设置 - 通用 - 关于本机里 型号一栏，例如：NQ9Y2CH/A，如果查询结果与GSX验机报告不一致，则是水货改国行）",
    };
    return array;
  } else if (type === "page") {
    inputValue.disabled = true;

    const array = {
      tips: "请使用要检测的手机扫下方二维码",
      placeholder: "无",
    };
    return array;
  }
};

// 获取每日免费次数
const getFreeNumStrong = (e) => {
  var FreeNumData = JSON.parse(localStorage.getItem("FreeNumData"));
  const userInfo = store.state.userInfo;
  const productId = userInfo.id + "p" + e;
  console.log(FreeNumData);
  if (typeof FreeNumData !== "undefined" && FreeNumData !== null) {
    FreeNumData = JSON.stringify(FreeNumData);
    if (
      FreeNumData.indexOf('"' + userInfo.id + '"') !== -1 &&
      FreeNumData.indexOf('"' + productId + '"') !== -1
    ) {
      // 有用户信息 有 商品id
      console.log("有用户信息 有商品id");
      FreeNumData = JSON.parse(FreeNumData);
      const currentTimeStamp = Date.parse(new Date()) / 1000;
      if (
        currentTimeStamp >
        FreeNumData[userInfo.id][productId].selectTime + 86400
      ) {
        delete FreeNumData[userInfo.id][productId];
        createFreeNumStrong(userInfo, productId, FreeNumData);
      } else {
        console.log("还没到刷新时间");
        freeNum.value = FreeNumData[userInfo.id][productId].num;
      }
    } else {
      // 之后的用户进入 或者 没有此商品id的
      FreeNumData = JSON.parse(FreeNumData);
      console.log("之后的用户进入 或者 没有此商品id的");
      createFreeNumStrong(userInfo, productId, FreeNumData);
    }
  } else {
    // 首个用户
    console.log("首个用户");
    createFreeNumStrong(userInfo, productId);
  }

  // if (typeof (FreeNumData) !== 'undefined' && FreeNumData !== null) {
  //   if (typeof (FreeNumData[userId]) !== 'undefined' && typeof (FreeNumData[userId][productId]) !== 'undefined') {
  //     // 有用户信息 有 商品id
  //     const currentTimeStamp = Date.parse(new Date()) / 1000
  //     if (currentTimeStamp > (FreeNumData[userInfo.id][productId].selectTime + 1)) {
  //       createFreeNumStrong(userInfo, productId, FreeNumData)
  //     } else {
  //       console.log('还没到刷新时间')
  //       freeNum.value = FreeNumData[userInfo.id][productId].num
  //     }
  //   } else {
  //     // 之后的用户进入 或者 没有此商品id的
  //     console.log('之后的用户进入 或者 没有此商品id的')
  //     createFreeNumStrong(userInfo, productId, FreeNumData)
  //   }
  // } else {
  // }
};

const createFreeNumStrong = (userInfo, productId, FreeNumData = {}) => {
  const num = userInfo.is_vip ? product.data.vip_num : product.data.free_num;
  FreeNumData = JSON.stringify(FreeNumData);
  var newFreeNumData = {};
  console.log(FreeNumData.indexOf(userInfo.id));
  if (FreeNumData.indexOf('"' + userInfo.id + '"') === -1) {
    console.log("FreeNumData");
    newFreeNumData = {
      [userInfo.id]: {
        [productId]: {
          num: num,
          selectTime: getCurrentDate(),
        },
      },
    };
    FreeNumData = JSON.parse(FreeNumData);
    FreeNumData = Object.assign(newFreeNumData, FreeNumData);
  } else if (FreeNumData.indexOf('"' + productId + '"') === -1) {
    console.log(123123);
    FreeNumData = JSON.parse(FreeNumData);
    console.log(FreeNumData);
    FreeNumData[userInfo.id][productId] = {
      num: num,
      selectTime: getCurrentDate(),
    };
  }
  console.log(productId);
  freeNum.value = num;
  localStorage.setItem("FreeNumData", JSON.stringify(FreeNumData));
};
</script>
<style scoped>
.home-tip {
  margin-top: 1rem;
  color: red;
}

/* #home button {
  background: rgb(21, 140, 251);
  color: white;
}

#home button:hover {
  background: rgb(4, 92, 253);
} */

#home >>> .el-dialog__body {
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
}

#home >>> .el-dialog__body img {
  padding: 10px;
  width: 200px;
  height: 250px;
}

.el-button--small {
  color: black;
}

.home-inputView {
  width: 450px;
  margin-bottom: 3rem;
}

/* .home-input {
  width: 500px;
}

.home-input >>> .el-input__inner{
  text-align: center;
  color: rgb(21,140,251);
  font-size: 1.5rem;
} */

#home input {
  padding: 1rem;
  margin: 2rem 0;
  width: 100%;
  outline: none;
  font-size: 1.5rem;
  text-align: center;
}

.home-btn {
  margin-top: 30px;
  width: 300px;
  outline: none;
}

.products {
  position: absolute;
  left: 0;
  top: 50;
  height: 95vh;
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: scroll;
}

.example {
  position: absolute;
  right: 0;
  top: 0;
  height: 95vh;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.vipDateView {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  #home input {
    width: 450px;
  }
  .scanModal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

/* iPhone 12 13 pro max*/
@media screen and (max-width: 430px) {
  #home input {
    width: 100%;
    font-size: 1.35rem;
  }
}

/* iPhone12 13*/
@media screen and (max-width: 390px) {
  #home input {
    width: 100%;
    font-size: 1.2rem;
  }
}

/* iPhone6 7 8 X / iphone13 mini*/
@media screen and (max-width: 376px) {
  #home input {
    width: 100%;
    font-size: 1.05rem;
  }
  .scanModal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

/* iPhone5 */
@media screen and (max-width: 321px) {
  #home input {
    width: 100%;
    font-size: 0.7rem;
  }
  .home-btn {
    width: 250px;
  }
}

.scanModal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>

<style>
/* iPhone12 13*/
@media screen and (min-width: 390px) and (max-width: 430px) {
  .ElMessageBoxStyle {
    width: 370px !important;
  }

  .modalStyle {
    width: 370px !important;
  }
}
/* iPhone5 */
@media screen and (min-width: 300px) and (max-width: 380px) {
  .ElMessageBoxStyle {
    width: 300px !important;
  }

  .modalStyle {
    width: 300px !important;
  }
  .modalStyle img:first-child {
    border-bottom: 1px solid rgb(204, 204, 204);
  }
}
</style>
